
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import Accordion from '../../components/references/accordion'
import AccordionGaro from '../../components/references/accordionGaro'
import { LocaleContext } from '../../locale-context';
export function SSS() {
  return (
    <S.Wrapper>
      <S.Top>

        <S.Header>

          <TranslateStatic string="SSSBaslik" />
        </S.Header>
      </S.Top>
      <S.Section>
        <LocaleContext.Consumer>
          {(value) => {
           if (value === "en") {
              return <AccordionGaro />
          
            }
          }}
        </LocaleContext.Consumer>
          <Accordion />
      </S.Section>
    </S.Wrapper >
  );
}
