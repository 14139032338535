import styled from "styled-components";

const S = {
    Wrapper: styled.div`
    width: 100%;
    padding:20px;
    @media (max-width:648px){
    
    }`,
    Table1:styled.table`
    overflow: auto;
    width: 100%;
    border: 1px solid #dededf;
    height: 100%;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 1px;
    text-align: left;
    th {
        border: 1px solid #dededf;
        background-color: #eceff1;
        color: #000000;
        padding: 5px;
    }
    td {
        border: 1px solid #dededf;
        background-color: #ffffff;
        color: #000000;
        padding: 5px;
    }
    `
    
}


export default S