import S from './style'
// import BANNERIMG from 'https://gelecektupbebek.com.tr/eski/Etkinlikler/2023-Ekim/1.png'
import TranslateStatic from '../../../locales/TranslateStatic'
export function News(){
    return(
        <S.Wrapper>
            <div className='imgbanner'>
            <div className='Gradient'>
                <h1>
                <TranslateStatic string="AylikBilimselToplantiTakvimiBaslik" />
                </h1>
            </div>
            <img src={'https://gelecektupbebek.com.tr/images/aylikbilimsel.png'}/>
            </div>
        </S.Wrapper>
    )
}