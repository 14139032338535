
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import { LocaleContext } from '../../locale-context';
import MeteIMG from '../../assets/ekip/mete.jpg'
import Icv from './icv';
export function Mete() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="teamHeader" />
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <S.Izgara>
          <div className='rowi'>
            <div className='items'>
              <img src={MeteIMG}></img>
              <h2><TranslateStatic string="MeteIsim" /></h2>
          
              <div className='content'>
                <p><TranslateStatic string="MeteItitre" /></p>
                <p><TranslateStatic string="UyteSorumlusu" /></p>
                <article><TranslateStatic string="MeteIsikoglu" /></article>
                <article><TranslateStatic string="MeteIsikoglu1" /></article>
              </div>

            </div>
          </div>
        </S.Izgara>

        <div>
          <LocaleContext.Consumer>
            {(value) => {
              if (value === "tr") {
                return <>
                  <div className='ozgecmis'>
                    <p>Gülhane Askeri Tıp Akademisi’nden 1994 yılında mezun olduktan sonra, 1996-2002 yılları arasında İstanbul Üniversitesi İstanbul Tıp Fakültesi’nde uzmanlık eğitimini tamamladı. Dünya Üreme Tıbbı Derneği bilimsel komitesinde görev yapmış olup, Avrupa IVF İzleme Konsorsiyumunda Türkiye temsilcisidir.  Antalya Tabip Odası, Üreme Endokrinolojisi ve İnfertilite Derneği (TSRM), Avrupa İnsan Üremesi ve Embryoloji Derneği (ESHRE) ve Preimplantasyon Genetik Tanı Uluslararası Derneği (PGDIS) üyesidir. Aynı zamanda TSRM ve Tüp Bebek Merkezleri Derneğinde yönetim kurulu üyesidir. Çok sayıda ulusal ve uluslararası yayınları ve sunumları mevcuttur. Bunun yanısıra çok sayıda ulusal ve uluslararası etkinlikte sunum ve konferanslar veren Işıkoğlu, yaklaşık 15 kitapta bölüm yazarlığı yapmıştır. </p>
                    <br />
                    <p>Aralarında Fertility Sterility, European Journal of Obstetrics and Gynecology and Reproductive Biology, Reproductive Biomedicine Online, Journal of Assisted Reproduction and Genetics, Journal of Gyneecology Research, International Journal of Fertility and Sterility and Turkish Journal of Obstetrics and Gynecology’nin de bulunduğu çeşitli ulusal ve uluslararası dergide hakemlik görevinde bulunmaktadır. Özel ilgi alanı endoskopik cerrahi olup, Avrupa Jinekolojik Cerrahi Akademisi tarafından verilen “Bachelor in Endoscopy Certificate” sahibidir.</p>
                    <br />
                    <p>Halihazırda Gelecek Tüp Bebek Merkezinde Üremeye Yardımcı Tedavi Merkezi Sorumlusu ve IVF uzmanı olarak görev yapmaktadır.</p>
                  </div>
                </>
              }
              else if (value === "en") {
                return <>
                  <div className='ozgecmis'>
                    <p>He obtained his medical degree in 1994 from Gulhane Medical School
                      and had OB&amp;GYN specialty training between 1996-2002 at Istanbul Medical
                      Faculty.</p>
                      <br />
                    <p>He is a member of Turkish Society of Gynecology and Obstetrics and
                      ESHRE. He has been acting as the national representative in European IVF
                      Monitoring Consortium. He has many international and national publications
                      and presentations; has also have given several international conferences and
                      contributed nearly 15 textbook chapters. He acts as a peer reviewer for
                      international scientific journals including Fertility Sterility, European Journal of
                      Obstetrics and Gynecology and Reproductive Biology, Reproductive
                      Biomedicine Online, Journal of Assisted Reproduction and Genetics, Journal
                      of Gyneecology Research, International Journal of Fertility and Sterility,
                      Turkish Journal of Obstetrics and Gynecology and several other international
                      journals.
                    </p>
                    <br />
                    <p>
                      He is especially interested in endoscopic surgery. He holds
                      “Bachelor in Endoscopy Certificate” given by the European Academy of
                      Gynaecological Surgery. His current position is director and consultant IVF
                      practitioner in Gelecek The Center For Human Reproduction/Antalya, Turkey.
                    </p>
                    
                  </div>
                </>
              }
            }}
          </LocaleContext.Consumer>
          <Icv />


        </div>
      </S.Section>
    </S.Wrapper >
  );
}
