import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    width: 100%;
    // height:200px;
    overflow:hidden;
    @media (max-width:648px){
        height:auto;
    }
    .imgbanner{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;  
        align-items:center;  
        position:relative;
        height:100%;
        
        .Gradient{
            width:100%;
            height:100%;
            z-index:1;
            color:black;
            padding:70px 50px;
            box-sizing:border-box;
            text-align:center;
            h1{
                font-size:2em;
                margin:0;
            }
            @media (max-width:648px){
                h1{
                    font-size:2em;
                }
            }
        }
        img{
            width:100%;
            height:600px;
            object-fit:contain;
            z-index:0;
           
            // object-position: 0px -300px;
            @media (max-width:648px){
                height:100%;
            }
        }
    }
    
    `,
  
}

export default S