import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Tabs from './tabs'
import TranslateStatic from '../../../locales/TranslateStatic';
import S from './style';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
        <AccordionSummary aria-controls="panel0d-content" id="panel0d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="GaroSSS0" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails>
          <b><TranslateStatic string="Days0" /></b>
          <ul>
            <li>
              <TranslateStatic string="GaroSSS0A-1" />
            </li>
            <li>
              <TranslateStatic string="GaroSSS0A-2" />
            </li>
            <li>
              <TranslateStatic string="GaroSSS0A-3" />
            </li>

          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="GaroSSS1" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails>
        <b><TranslateStatic string="Days1" /></b>
          <ul>
            <li>
              <TranslateStatic string="GaroSSS1A-1" />
            </li>
            <li>
              <TranslateStatic string="GaroSSS1A-2" />
            </li>
            <li>
              <TranslateStatic string="GaroSSS1A-3" />
            </li>

          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="GaroSSS2" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails>
        <b><TranslateStatic string="Days2" /></b>
          <ul>
            <li>
              <TranslateStatic string="GaroSSS2A-1" />
            </li>
            <li>
              <TranslateStatic string="GaroSSS2A-2" />
            </li>


          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="GaroSSS3" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails>
        <b><TranslateStatic string="Days3" /></b>
          <ul>
            <li>
              <TranslateStatic string="GaroSSS3A-1" />
            </li>
            <li>
              <TranslateStatic string="GaroSSS3A-2" />
            </li>


          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="GaroSSS4" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails>
        <b><TranslateStatic string="Days4" /></b>
          <ul>
            <li>
              <TranslateStatic string="GaroSSS4A-1" />
            </li>
            <li>
              <TranslateStatic string="GaroSSS4A-2" />
            </li>


          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="GaroSSS5" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails>
        <b><TranslateStatic string="Days5" /></b>
          <ul>
            <li>
              <TranslateStatic string="GaroSSS5A-1" />
            </li>
            <li>
              <TranslateStatic string="GaroSSS5A-2" />
            </li>


          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="GaroSSS6" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails>
        <b><TranslateStatic string="Days6" /></b>
          <ul>
            <li>
              <TranslateStatic string="GaroSSS6A-1" />
            </li>
            <li>
              <TranslateStatic string="GaroSSS6A-2" />
            </li>


          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="GaroSSS7" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails>
        <b><TranslateStatic string="Days7" /></b>
          <ul>
            <li>
              <TranslateStatic string="GaroSSS7A-1" />
            </li>
            <li>
              <TranslateStatic string="GaroSSS7A-2" />
            </li>


          </ul>
          {/* <S.Table1>
            <thead>
              <tr>
                <th>Stage</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pre-Treatment Assessment</td>
                <td>1–2 days</td>
              </tr>
              <tr>
                <td>Ovarian Stimulation</td>
                <td>10-13 days</td>
              </tr>
              <tr>
                <td>Egg Retrieval and Fertilization</td>
                <td>5-15 minute</td>
              </tr>
              <tr>
                <td>Embryo Development</td>
                <td>5–6 days</td>
              </tr>
              <tr>
                <td>Embryo Transfer </td>
                <td>3–5 days post-retrieval or later for frozen cycles</td>
              </tr>
              <tr>
                <td>Waiting Period (Two week wait)</td>
                <td>10–14 days</td>
              </tr>
              <tr>
                <td>Pregnancy Testing and Monitoring</td>
                <td>Initial 1–2 weeks of confirmed pregnancy</td>
              </tr>
            </tbody>
          </S.Table1> */}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel78d-content" id="panel8d-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            <TranslateStatic string="GaroSSS8" />
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="SSS1A"/></Typography> */}
        </AccordionSummary>
        {/* <Tabs.Otels/> */}
        <AccordionDetails>

          <ul>
            <li>
              <TranslateStatic string="GaroSSS8A-1" />
            </li>
           


          </ul>
          <S.Table1>
            <thead>
              <tr>
                <th>Stage</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pre-Treatment Assessment</td>
                <td>1–2 days</td>
              </tr>
              <tr>
                <td>Ovarian Stimulation</td>
                <td>10-13 days</td>
              </tr>
              <tr>
                <td>Egg Retrieval and Fertilization</td>
                <td>5-15 minute</td>
              </tr>
              <tr>
                <td>Embryo Development</td>
                <td>5–6 days</td>
              </tr>
              <tr>
                <td>Embryo Transfer </td>
                <td>3–5 days post-retrieval or later for frozen cycles</td>
              </tr>
              <tr>
                <td>Waiting Period (Two week wait)</td>
                <td>10–14 days</td>
              </tr>
              <tr>
                <td>Pregnancy Testing and Monitoring</td>
                <td>Initial 1–2 weeks of confirmed pregnancy</td>
              </tr>
            </tbody>
          </S.Table1>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
